<script>
import { format } from 'date-fns'
export default {
  props: {
    event: {
      type: Object,
      default: () => {}
    }
  },
  render () {
    return this.$scopedSlots.default({
      formatFullDate: this.formatFullDate,
      formatTitleDate: this.formatTitleDate,
      eventStartingSoon: this.eventStartingSoon,
      getStatus: this.getStatus
    })
  },
  computed: {
    eventStartingSoon () {
      const now = new Date()
      const start = new Date(this.event.start_date)
      const end = new Date(this.event.end_date)
      return now > start - 1800000 && now < end // 1800000 is 30 minutes
    },
    getStatus () {
      return (this.event.denied && 'denied') ||
      (this.event.approved && 'approved') ||
      (this.event.pending && 'pending') ||
      ''
    }
  },
  methods: {
    formatFullDate (date) {
      const formatString = 'YYYY-MM-DD HH:mm:ss'
      return format(date, formatString)
    },
    formatTitleDate () {
      let formatString = 'MMM-Do-YYYY'
      const startDate = format(this.event.start_date, formatString)
      const endDate = format(this.event.end_date, formatString)
      formatString = 'hh:mma'
      const startTime = format(this.event.start_date, formatString)
      const endTime = format(this.event.end_date, formatString)
      return `${startDate} - ${endDate} ${startTime} - ${endTime}`
    }
  }
}
</script>
