import transactions from './event-transactions'

const modules = {
  transactions
}

const state = {
  events: null
}

const mutations = {
  setEvents (state, payload) {
    state.events = payload
  }
}

export default {
  namespaced: true,
  modules,
  state,
  mutations
}
