import api from '../../api'
import store from '@/store'

const {
  getEligibility,
  getTraining,
  updateTraining,
  getReservations,
  getPendingReservations,
  getReservationsAdmin,
  getUsageHistory,
  getReservationsPast,
  getContact,
  uploadSafetyPlan,
  getEquipment,
  createReservation,
  getOverviewAvailability,
  getAllAvailability,
  isTrackAvailable,
  getReservation,
  saveReservation,
  workflowApprove,
  workflowDeny,
  cancelEvent,
  copySafetyPlan
} = api

const actions = {
  getEligibilityAction ({ commit }) {
    return getEligibility()
      .then(response => commit('reservation/setEligibility', response.data.eligibility, { root: true }))
      .catch(e => commit('logError', e, { root: true }))
  },
  addAdditionalContactAction ({ commit }, contact) {
    return getTraining(contact.email)
      .then((response) => {
        // checks if training date is within a year of reservation date
        const YEAR = 31536000e3 // seconds in a year
        var trainingDate = new Date(response.data.contact.track_rental_dates.training_date)
        var resDate = new Date(store.state.reservation.reservationForm.start_date)
        if (trainingDate != null && resDate.getTime() - trainingDate.getTime() < YEAR) {
          contact.training = true
        }
        commit('reservation/addAdditionalContact', contact, { root: true })
      })
      .catch(e => {
        // server throws error for not found emails
        commit('reservation/addAdditionalContact', contact, { root: true })
        commit('logError', e, { root: true })
      })
  },
  getTrainingAction ({ commit }, email) {
    return getTraining(email)
      .then((response) => {
        const YEAR = 31536000e3 // seconds in a year
        var trainingDate = new Date(response.data.contact.track_rental_dates.training_date)
        var resDate = new Date()
        if (trainingDate == null || resDate.getTime() - trainingDate.getTime() > YEAR) {
          commit('setTrainingRequired', true, { root: true })
        }
      })
      .catch(e => {
        // server throws error for not found emails
        commit('setTrainingRequired', true, { root: true })
        commit('logError', e, { root: true })
      })
  },
  updateTrainingAction ({ commit }, phone) {
    return updateTraining(phone)
      .catch(e => commit('logError', e, { root: true }))
  },
  getReservationsAction ({ commit }) {
    return getReservations()
      .then(response => commit('reservation/setReservations', response.data.reservations, { root: true }))
      .catch(e => commit('logError', e, { root: true }))
  },
  getReservationAction ({ commit }, eventID) {
    return getReservation(eventID)
      .then(response => commit('reservation/setCurrentReservation', response.data.reservation, { root: true }))
      .catch(e => commit('logError', e, { root: true }))
  },
  getReservationsPastAction ({ commit }) {
    return getReservationsPast()
      .then(response => commit('reservation/setReservationsPast', response.data.reservations, { root: true }))
      .catch(e => commit('logError', e, { root: true }))
  },
  getContactAction ({ commit }) {
    return getContact()
      .then(response => commit('reservation/setContactFields', response.data.contact, { root: true }))
      .catch(e => commit('logError', e, { root: true }))
  },
  uploadSafetyPlanAction ({ commit, rootState }) {
    const formData = new FormData()
    formData.append('media_type_desc', 'TestPlan')
    formData.append('user_file', rootState.reservation.safetyPlan)
    return uploadSafetyPlan(rootState.reservation.currentEventID, formData)
      .then(response => {
        commit('reservation/setSafetyPlan', null, { root: true })
        commit('reservation/updateSafetyPlan', response.data.item, { root: true })
      }
      )
      .catch(e => commit('logError', e, { root: true }))
  },
  copySafetyPlanAction ({ commit, rootState }) {
    const key = rootState.reservation.currentReservation.reservation_key
    return copySafetyPlan(rootState.reservation.currentEventID, { reservation_key: key })
  },
  getEquipmentAction ({ commit, rootState }) {
    const { start_date: startDate, end_date: endDate, timeslot: timeslotCode } = rootState.reservation.reservationForm
    return getEquipment(startDate, endDate, timeslotCode)
      .then(response => commit('reservation/setEquipment', response.data.equipment, { root: true }))
      .catch(e => commit('logError', e, { root: true }))
  },
  createReservationAction ({ commit, rootState, dispatch }) {
    rootState.reservation.reservationForm.addl_contacts = rootState.reservation.reservationForm.addl_contacts.filter((contact) => contact?.email !== '' && contact?.name !== '')
    return createReservation(rootState.reservation.reservationForm)
      .then((response) => {
        commit('reservation/setCurrentEventID', response.data.reservation.event_id, { root: true })
        dispatch('uploadSafetyPlanAction', rootState.reservation.currentEventID)
        dispatch('getReservationsAction')
      })
      .catch(e => commit('logError', e, { root: true }))
  },
  createTourAction ({ commit, rootState, dispatch }) {
    return createReservation(rootState.reservation.reservationForm)
      .then((response) => {
        commit('reservation/setCurrentEventID', response.data.reservation.event_id, { root: true })
        dispatch('getReservationsAction')
      })
      .catch(e => commit('logError', e, { root: true }))
  },
  createHoldAction ({ commit, rootState, dispatch }) {
    return createReservation(rootState.reservation.reservationForm)
      .then((response) => {
        commit('reservation/setCurrentEventID', response.data.reservation.event_id, { root: true })
        dispatch('getReservationsAction')
      })
      .catch(e => commit('logError', e, { root: true }))
  },
  createTemplateReservationAction ({ commit, rootState, dispatch }) {
    return createReservation(rootState.reservation.reservationForm)
      .then((response) => {
        commit('reservation/setCurrentEventID', response.data.reservation.event_id, { root: true })
        dispatch('uploadSafetyPlanAction', rootState.reservation.currentEventID)
      })
      .catch(e => commit('logError', e, { root: true }))
  },
  saveReservationAction ({ commit, rootState, dispatch }) {
    return saveReservation(rootState.reservation.currentEventID, rootState.reservation.reservationForm)
      .then(response => {
        commit('reservation/setCurrentEventID', response.data.reservation.event_id, { root: true })
        if (rootState.reservation.safetyPlan) {
          dispatch('uploadSafetyPlanAction')
        }
      })
      .catch(e => commit('logError', e, { root: true }))
  },
  getOverviewAvailabilityAction ({ commit }) {
    return getOverviewAvailability()
      .then(response => commit('reservation/setOverviewAvailability', response.data.availability, { root: true }))
      .catch(e => commit('logError', e, { root: true }))
  },
  getAllAvailabilityAction ({ commit, rootState }) {
    if (rootState.reservation.allAvailability.length === 0) {
      return getAllAvailability()
        .then((response) => {
          commit('reservation/setAllAvailability', response.data.availability, { root: true })
        })
        .catch(e => commit('logError', e, { root: true }))
    }
  },
  getPendingReservationsAction ({ commit }) {
    return getPendingReservations()
      .then(response => commit('reservation/setPendingReservations', response.data.reservations, { root: true }))
      .catch(e => commit('logError', e, { root: true }))
  },
  getReservationsAdminAction ({ commit }) {
    return getReservationsAdmin()
      .then(response => commit('reservation/setReservationsAdmin', response.data.events, { root: true }))
      .catch(e => commit('logError', e, { root: true }))
  },
  getUsageHistoryAction ({ commit }, dates) {
    return getUsageHistory(dates.startDate, dates.endDate)
      .then(response => commit('reservation/setUsageHistory', response.data.events, { root: true }))
      .catch(e => commit('logError', e, { root: true }))
  },
  workflowApproveAction ({ commit, dispatch }, eventID) {
    return workflowApprove(eventID)
      .then(response => {
        dispatch('getReservationsAdminAction')
        dispatch('getPendingReservationsAction')
      })
      .catch(e => commit('logError', e, { root: true }))
  },
  workflowDenyAction ({ commit, dispatch }, eventID) {
    return workflowDeny(eventID)
      .then(response => {
        dispatch('getReservationsAdminAction')
        dispatch('getPendingReservationsAction')
      })
      .catch(e => commit('logError', e, { root: true }))
  },
  cancelEventAction ({ commit, dispatch }, eventID) {
    return cancelEvent(eventID)
      .then(() => dispatch('getReservationsAction'))
      .catch(e => commit('logError', e, { root: true }))
  },
  isTrackAvailableAction ({ commit, rootState, dispatch }) {
    const slot = rootState.reservation.reservationForm.timeslot
    const partial = rootState.reservation.reservationForm.partial_reservation
    const date = new Date(rootState.reservation.reservationForm.start_date)

    return isTrackAvailable(slot, partial, date)
      .then(response => {
        return response.data.available
      })
      .catch(e => commit('logError', e, { root: true }))
  }
}

export default {
  namespaced: true,
  actions
}
