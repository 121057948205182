<template>
  <div>
    <v-card>
      <v-card-title class="headline primary white--text">
        Waitlists
      </v-card-title>
      <v-progress-circular
        indeterminate
        class="mt-2"
        size="50"
        v-if="showLoader"
      />
      <v-card-text class="text-xs-left">
        <template v-if="waitlists.length === 0">
          <span class="subheading">You don't have any active waitlist entries.</span>
        </template>
        <template v-else>
          <v-list>
            <v-list-tile
              v-for="(waitlist, index) in waitlists"
              :key="index"
            >
              <font-awesome-icon
                size="2x"
                :icon="['far', 'clock']"
              />
              <v-spacer />
              <v-list-tile-content>
                <v-list-tile-sub-title class="waitlist-daterange">
                  {{ formatWaitlistRange(waitlist) }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn
                  color="error"
                  @click="openCancelDialog(waitlist)"
                  data-cy="waitlist-cancel"
                >
                  Cancel
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="this.disableCreateButton"
          @click="showNewWaitlistDialog()"
          data-cy="waitlist-new"
        >
          Create New Waitlist
        </v-btn>
        <template v-if="waitlists.length >= 3">
          <v-spacer />
          <span class="subheading"> There is a limit of 3 waitlists per user </span>
        </template>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="showWaitlistCancelConfirmDialog"
      width="500"
    >
      <v-card data-cy="waitlist-cancel-entry-dialog">
        <v-card-title class="primary white--text">
          <h2 class="heading">
            Waitlist Cancellation Confirmation
          </h2>
        </v-card-title>
        <v-card-text class="text-xs-left">
          <p>Are you sure you want to cancel the following waitlist?</p>
          {{ formatWaitlistRange(this.waitlistToCancel) }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showWaitlistCancelConfirmDialog = false">
            Close
          </v-btn>
          <v-btn
            @click="deleteWaitlist()"
            color="error"
            data-cy="waitlist-cancel"
          >
            Cancel Waitlist
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="newWaitlistDialogVisible"
      width="500"
    >
      <v-card data-cy="new-waitlist-entry-dialog">
        <v-card-title class="primary white--text">
          <h2 class="heading">
            New Waitlist Request
          </h2>
        </v-card-title>
        <v-card-text class="text-xs-left">
          <p>
            Provide the information below to be notified by email if any reservations get canceled during the time
            window you provide. Waitlists are limited to 10 days.
          </p>
        </v-card-text>
        <v-layout justify-center>
          <v-flex xs11>
            <v-menu
              ref="startDateMenu"
              v-model="startDateMenu"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <v-text-field
                slot="activator"
                outline
                v-model="startDate"
                label="Start Date"
                prepend-icon="fa-calendar-alt"
                readonly
              />
              <v-date-picker
                v-model="startDate"
                no-title
                scrollable
                color="primary"
                :min="this.dateProp"
              />
            </v-menu>
            <v-menu
              ref="endDateMenu"
              v-model="endDateMenu"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <v-text-field
                slot="activator"
                outline
                v-model="endDate"
                label="End Date"
                prepend-icon="fa-calendar-alt"
                readonly
              />
              <v-date-picker
                v-model="endDate"
                no-title
                scrollable
                color="primary"
                :min="this.startDate"
                :max="getMaxEndDate().toISOString()"
              />
            </v-menu>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-btn @click="newWaitlistDialogVisible = false">
            Close
          </v-btn>
          <v-btn
            @click="createNewWaitlistEntry()"
            color="primary"
            data-cy="waitlist-create-entry"
          >
            Create Waitlist
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* This component displays the confirmation page to create a waitlist entry */
import { mapState, mapActions } from 'vuex'
import api from '../api'
import { format } from 'date-fns'

export default {
  props: {
    dateProp: {
      type: String,
      default: (new Date()).toISOString().slice(0, 10)
    }
  },
  data () {
    return {
      waitlistEntries: [],
      showLoader: false,
      newWaitlistDialogVisible: false,
      currentSelection: {
        startDate: null,
        endDate: null,
        consecutiveDays: 1,
        timeslots: []
      },
      today: new Date(),
      startDate: this.dateProp,
      endDate: this.dateProp,
      waitlistToCancel: null,
      showWaitlistCancelConfirmDialog: false,
      disableCreateButton: false
    }
  },
  methods: {
    ...mapActions('waitlist/transactions', [
      'getWaitlistsAction']),
    showNewWaitlistDialog () {
      this.newWaitlistDialogVisible = true
    },
    getMaxEndDate () {
      const maxEndDate = new Date(this.startDate)
      maxEndDate.setDate(maxEndDate.getDate() + 10)
      return maxEndDate
    },
    openCancelDialog (waitlist) {
      this.waitlistToCancel = waitlist
      this.showWaitlistCancelConfirmDialog = true
    },
    deleteWaitlist () {
      api.deleteWaitlist(this.waitlistToCancel.key)
        .then(response => {
          this.fetchWaitlistsAndUpdateView()
        })
      this.showWaitlistCancelConfirmDialog = false
      this.waitlistToCancel = null
    },
    toISOStringNoTime (date) {
      return date.toISOString().slice(0, 10)
    },
    formatWaitlistRange (waitlist) {
      if (waitlist != null) {
        const startDate = waitlist.start_date
        const endDate = waitlist.end_date
        return `${this.formatWaitlistDate(startDate)} - ${this.formatWaitlistDate(endDate)}`
      }
      return ''
    },
    formatWaitlistDate (date) {
      return format(this.adjustForUTCOffset(new Date(date)), 'MMM-Do-YYYY')
    },
    adjustForUTCOffset (date) {
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds())
    },
    createNewWaitlistEntry () {
      this.newWaitlistDialogVisible = false
      api.createWaitlist({
        start_date: new Date(this.startDate.toString()),
        end_date: new Date(this.endDate.toString()),
        email: ''
      })
        .then(response => {
          this.fetchWaitlistsAndUpdateView()
        })
    },
    fetchWaitlistsAndUpdateView () {
      this.getWaitlistsAction().then(() => {
        this.disableCreateButton = this.waitlists.length >= 3
      })
    }
  },
  computed: {
    ...mapState('waitlist', ['waitlists'])
  },
  watch: {
    startDate (newStartDate) {
      // When start date changes, adjust the end date to keep users from requesting waitlists longer than 10 days
      newStartDate = new Date(newStartDate)
      const _ = new Date(newStartDate)
      const currentEndDate = new Date(this.endDate)
      const newMaxEndDate = new Date(_.setDate(newStartDate.getDate() + 10))
      if (newMaxEndDate < currentEndDate) {
        this.endDate = this.toISOStringNoTime(newMaxEndDate)
      }
      if (currentEndDate < newStartDate) {
        this.endDate = this.toISOStringNoTime(newStartDate)
      }
    }
  },
  mounted () {
    this.fetchWaitlistsAndUpdateView()
  }
}
</script>
<style>
  .waitlist-daterange {
    font-size: 18px;
  }
</style>
