<template>
  <font-awesome-layers :class="size">
    <font-awesome-icon
      :icon="['far', 'traffic-light']"
      size="4x"
    />
    <font-awesome-icon
      icon="circle"
      :color="color"
      :transform="transform"
    />
  </font-awesome-layers>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: 'denied'
    },
    size: {
      type: String,
      default: 'fa-1x'
    }
  },
  computed: {
    color () {
      switch (this.status) {
        case 'pending':
          return '#e7b416'
        case 'approved':
          return '#2dc937'
        case 'denied':
        default:
          return '#cc3232'
      }
    },
    transform () {
      switch (this.status) {
        case 'pending':
          return 'shrink-2 right-16'
        case 'approved':
          return 'shrink-2 right-16 down-16'
        default:
          return 'shrink-2 right-16 up-16'
      }
    }
  }
}
</script>
