import axios from 'axios'

export default {
  getEligibility: () => {
    return axios.get('/api/eligibility')
  },
  getTraining: (email) => {
    return axios.get('https://keys.um.city/api/track/training', {
      params: {
        email: email
      }
    })
  },
  updateTraining: (phone) => {
    return axios.post('/api/track/training', { phone: phone })
  },
  getEvents: () => {
    return axios.get('/api/calendar')
  },
  getTimeslots: () => {
    return axios.get('/api/timeslots')
  },
  getEquipment: (startDate, endDate, timeslotCode) => {
    return axios.get('/api/equipment', {
      params: {
        start_date: startDate,
        end_date: endDate,
        timeslot_cd: timeslotCode
      }
    })
  },
  getReservations: () => {
    return axios.get('/api/reservations')
  },
  getReservationsPast: () => {
    return axios.get('/api/reservations/past')
  },
  getReservation: (eventID) => {
    return axios.get(`/api/reservation/${eventID}`)
  },
  getContact: () => {
    return axios.get('/api/contact')
  },
  uploadSafetyPlan: (eventID, data) => {
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return axios.post(`/api/reservation/${eventID}/media`, data, options)
  },
  copySafetyPlan: (eventID, data) => {
    return axios.put(`/api/reservation/${eventID}/safetyplan`, data)
  },
  createWaitlist: (waitlist) => {
    return axios.post('api/waitlists', waitlist)
  },
  getWaitlists: () => {
    return axios.get('api/waitlists')
  },
  deleteWaitlist: (waitlistId) => {
    return axios.delete(`/api/waitlists/${waitlistId}`)
  },
  createReservation: (reservation) => {
    return axios.post('api/reservation', reservation)
  },
  getOverviewAvailability: () => {
    return axios.get('/api/calendar/track/overview')
  },
  getAllAvailability: () => {
    return axios.get('/api/calendar/track/available')
  },
  isTrackAvailable: (slot, partial, date) => {
    const params = { params: { slot: slot, partial: partial, date: date } }
    return axios.get('/api/calendar/track/available/slot', params)
  },
  saveReservation: (eventID, reservation) => {
    if ('tech_time' in reservation) {
      reservation.tech_time = parseFloat(reservation.tech_time)
    }
    return axios.patch(`/api/reservation/${eventID}`, reservation)
  },
  getPendingReservations: () => {
    return axios.get('/api/workflow')
  },
  getReservationsAdmin: () => {
    return axios.get('/api/calendar/track')
  },
  getUsageHistory: (startDate, endDate) => {
    return axios.get(`/api/calendar/usage/${startDate}/${endDate}`)
  },
  saveReservationNotes: (eventID, notes) => {
    return axios.post(`/api/reservation/${eventID}/notes`, { notes: notes })
  },
  pullReservationNotes: (eventID) => {
    return axios.get(`/api/reservation/${eventID}/notes`)
  },
  workflowApprove: (eventID) => {
    return axios.post(`/api/workflow/approve/${eventID}`)
  },
  workflowDeny: (eventID) => {
    return axios.post(`/api/workflow/deny/${eventID}`)
  },
  cancelEvent: (eventID) => {
    return axios.delete(`/api/reservation/${eventID}`)
  },
  initializeBilling: (date) => {
    return axios.post(`/api/billing/${date}`)
  },
  getBillingItems: (date) => {
    return axios.get(`/api/billing/get/${date}`)
  },
  deleteBillingItem: (key) => {
    return axios.delete(`/api/billing/item/${key}`)
  },
  getRates: (month) => {
    return axios.get(`/api/rates/${month}`)
  },
  getRole: (date) => {
    return axios.get(`/api/rates/role/${date}`)
  },
  patchBillingLineItem: (data) => {
    return axios.patch(`/api/billing/item/${data.billing_item_key}`, data)
  },
  createBillingLineItem: (data) => {
    return axios.post(`/api/billing/item/${data.event_id}`, data)
  },
  finalizeBillingItems: (date) => {
    return axios.get(`/api/billing/export/${date}`)
  },
  dumpBillingData: (date) => {
    return axios.get(`/api/billing/dump/${date}`, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0'
      }
    })
  },
  getUsageReportData: (date, companyName, cancelToken) => {
    return axios.get(`/api/usage/${date}`, { params: { company: companyName }, cancelToken })
  },
  getUsageReportDataRange: (startDate, endDate, companyName, cancelToken) => {
    return axios.get(`/api/usage/${startDate}/${endDate}`, { params: { company: companyName }, cancelToken })
  },
  getActiveGrants: () => {
    return axios.get('/api/grants/active')
  }
}
