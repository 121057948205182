import '@babel/polyfill'
import Vue from 'vue'
import VueMultianalytics from 'vue-multianalytics'
import './plugins/vuetify'
import './plugins/vuelidate'
import App from './App.vue'
import router from './router'
import store from './store'
import setupInterceptors from './interceptors'
import * as Sentry from '@sentry/vue'

setupInterceptors()

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueMultianalytics, {
    modules: {
      mixpanel: { token: process.env.VUE_APP_MIXPANEL_TOKEN }
    },
    routing: {
      vueRouter: router
    }
  })
}

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <svg>.'
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null
    vm = null
    trace = null
  }
}

// Ensures Vue errors are still logged in dev (sentry normally intercepts them)
Vue.config.errorHandler = (err, vm, info) => {
  if (process.env.NODE_ENV !== 'production') {
    Vue.util.warn(`Error in ${info}: "${err.toString()}"`, vm)
  }
  console.error(err) // eslint-disable-line no-console
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

if (process.env.DEBUG === 'False') {
  // Setup for Sentry.io error tracking
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration()
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}
