import api from '../../api'

const {
  getEvents
} = api

const state = {

}

const actions = {
  getEventsAction ({ commit }) {
    return getEvents()
      .then(response => commit('event/setEvents', response.data.events, { root: true }))
      .catch(e => commit('logError', e, { root: true }))
  }
}

export default {
  namespaced: true,
  state,
  actions
}
