import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import StatusLight from '@/components/StatusLight'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import {
  faTools,
  faCircle as fasFaCircle,
  faCheck as fasFaCheck,
  faBars,
  faExclamationTriangle,
  faTimesCircle as fasFaTimeCircle,
  faChevronDown,
  faBan as fasFaBan,
  faCaretDown,
  faCheckSquare,
  faSortUp,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faHashtag,
  faExclamation,
  faPen,
  faTrash,
  faPlus
} from '@fortawesome/pro-solid-svg-icons'
import {
  faTrafficLight,
  faTimesCircle as farFaTimeCircle,
  faCheckCircle,
  faExclamationCircle,
  faEdit,
  faCar,
  faFileInvoice,
  faCalendarExclamation,
  faBan as farFaBan,
  faCheck as farFaCheck,
  faSquare,
  faDotCircle,
  faCircle as farFaCircle,
  faClock
} from '@fortawesome/pro-regular-svg-icons'
import 'vuetify/src/stylus/app.styl'

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
library.add(
  faTools,
  fasFaCircle,
  fasFaCheck,
  faBars,
  faExclamationTriangle,
  faExclamationCircle,
  fasFaTimeCircle,
  faChevronDown,
  farFaTimeCircle,
  faExclamationCircle,
  faTrafficLight,
  faCheckCircle,
  faEdit,
  faCar,
  faFileInvoice,
  fasFaBan,
  faDotCircle,
  farFaBan,
  farFaCheck,
  faCaretDown,
  faCheckSquare,
  faSortUp,
  faChevronLeft,
  faChevronRight,
  faSquare,
  faCalendarAlt,
  faCalendarExclamation,
  faHashtag,
  farFaCircle,
  faExclamation,
  faPen,
  faTrash,
  faPlus,
  faClock
)

Vue.use(Vuetify, {
  theme: {
    // Michigan colors http://med.umich.edu/branding/color.html
    primary: '#00274C',
    secondary: '#FFC400',
    pop: '#575294',
    accent: '#01274b',
    error: '#9A3324',
    arbblue: '#0174BB',
    canhamblue: '#587abc',
    lsaorange: '#cc6600',
    hillbrown: '#7a121c',
    rackhamgreen: '#83b2a8'
  },
  iconfont: 'faSvg',
  icons: {
    statuslight: {
      component: StatusLight
    }
  }
})
