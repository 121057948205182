import transactions from './waitlist-transactions'

const modules = {
  transactions
}

const state = {
  waitlists: []
}

const mutations = {
  setWaitlists (state, payload) {
    state.waitlists = payload
  }
}

export default {
  namespaced: true,
  modules,
  mutations,
  state
}
