<template>
  <v-container
    grid-list-md
    fluid
  >
    <v-layout
      wrap
      justify-start
    >
      <v-flex
        xs12
        md6
      >
        <v-card>
          <v-card-title class="headline primary white--text">
            Track Availability
          </v-card-title>
          <font-awesome-icon
            color="green"
            :icon="['far', 'check-circle']"
          />: one of daytime (am/pm) timeslots available on this day
          <br><font-awesome-icon
            color="tomato"
            :icon="['far', 'times-circle']"
          />: day passed or both daytime slots not available
          <br><font-awesome-icon
            color="#FFCB05"
            :icon="['far', 'car']"
          />/<font-awesome-icon
            color="#00274c"
            :icon="['far', 'car']"
          />: pending/approved reservation on this day
          <calendar-overview />
        </v-card>
        <reservations-past class="my-3" />
        <!-- <billing class="mb-3" /> -->
      </v-flex>
      <v-flex
        xs12
        md6
      >
        <reservation-issues class="mb-3" />
        <reservations class="mb-3" />
        <waitlist class="mb-3" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
/* This component serves as the homepage for the track scheduling application */
import Reservations from './Reservations.vue'
import ReservationsPast from './ReservationsPast.vue'
import Waitlist from './Waitlist.vue'
import ReservationIssues from './ReservationIssues'
import CalendarOverview from './CalendarOverview'
export default {
  components: {
    Reservations,
    Waitlist,
    ReservationIssues,
    CalendarOverview,
    ReservationsPast
  },
  mounted () {
    this.$store.dispatch('dimensions/getTimeslotsAction')
  }
}
</script>
