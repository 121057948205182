import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import AvailableList from '@/components/AvailableList'
import Home from '@/components/Home'
import Waitlist from '@/components/Waitlist'
import AuthComponent from '@mcity/mcity-vue-auth/src/components/AuthComponent.vue'
import { checkRequiresAuth } from '@mcity/mcity-vue-auth/dist/router/beforeEachHooks'

Vue.use(Router)
// Enables auth on routes if VUE_APP_TESTING env var is set to false
const testMode = !(process.env.VUE_APP_TESTING === 'true')
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      meta: {
        requiresAuth: testMode,
        requiresApproved: true
      },
      beforeEnter: (to, from, next) => {
        const isEligible = eligibility => eligibility.insured && eligibility.insurance_expiry
        const allowOrDeny = () => {
          if (isEligible(store.state.reservation.eligibility)) {
            next()
          } else {
            next('/eligibility')
          }
        }
        if (store.state.reservation.eligibility) {
          allowOrDeny()
        } else {
          store.commit('setIsUserLoading', true)
          store.dispatch('reservation/transactions/getEligibilityAction')
            .then(() => {
              allowOrDeny()
              store.commit('setIsUserLoading', false)
            })
        }
      }
    },
    {
      path: '/search',
      name: 'Search',
      component: AvailableList,
      meta: {
        requiresAuth: testMode,
        requiresApproved: true
      },
      props: route => ({ date: route.query.date })
    },
    {
      path: '/eligibility',
      name: 'Eligibility',
      component: () => import('@/components/NotEligible'),
      meta: {
        requiresAuth: testMode,
        requiresApproved: true
      }
    },
    {
      path: '/reservations',
      name: 'Reservations',
      component: () => import('@/components/Reservations'),
      meta: {
        requiresAuth: testMode,
        requiresApproved: true
      }
    },
    {
      path: '/reservation/:eventID/edit',
      name: 'Edit',
      component: () => import('@/components/EditReservation'),
      meta: {
        requiresAuth: testMode,
        requiresApproved: true
      },
      beforeEnter (to, from, next) {
        store.dispatch('reservation/transactions/getReservationAction', to.params.eventID)
          .then(() => next())
      }
    },
    {
      path: '/reservation/:eventID/view',
      name: 'View',
      component: () => import('@/components/ReservationPreview'),
      beforeEnter (to, from, next) {
        store.dispatch('reservation/transactions/getReservationAction', to.params.eventID)
          .then(() => next())
      },
      meta: {
        requiresAuth: testMode,
        requiresApproved: true
      }
    },
    {
      path: '/calendar',
      name: 'Events',
      component: () => import('@/components/EventList'),
      meta: {
        requiresAuth: testMode,
        requiresApproved: true
      }
    },
    {
      path: '/create',
      name: 'Create',
      component: () => import('@/components/NewReservation'),
      meta: {
        requiresAuth: testMode,
        requiresApproved: true
      }
    },
    {
      path: '/waitlist',
      name: 'Waitlist',
      component: Waitlist,
      meta: {
        requiresAuth: testMode,
        requiresApproved: true
      }
    },
    {
      path: '/admin',
      name: 'Admin',
      component: () => import('@/components/AdminOverview'),
      meta: {
        requiresAuth: testMode,
        requiresApproved: true
      }
    },
    {
      path: '/usage',
      name: 'Usage Dashboard',
      component: () => import('@/components/UsageDashboard'),
      meta: {
        requiresAuth: testMode,
        requiresApproved: true
      }
    },
    {
      path: '/authorized',
      name: 'OverviewAuth',
      components: {
        auth: AuthComponent
      },
      meta: {
        authorized: true
      }
    },
    {
      path: '/training',
      name: 'Track Training',
      component: () => import('@/components/Training'),
      meta: {
        requiresAuth: testMode,
        requiresApproved: true
      }
    },
    {
      path: '*',
      name: 'Error',
      component: () => import('@mcity/mcity-vue-shared/components/NotFound')
    }
  ]
})

router.beforeEach(checkRequiresAuth.bind({ $store: store }))

export default router
