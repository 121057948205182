import Vuex from 'vuex'
import Vue from 'vue'
import event from './modules/event'
import reservation from './modules/reservation'
import waitlist from './modules/waitlist'
import dimensions from './modules/dimensions'
import session from '@mcity/mcity-vue-auth/dist/store/session'
import * as Sentry from '@sentry/browser'

Vue.config.devtools = true
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    reservation,
    event,
    dimensions,
    waitlist,
    session
  },
  state: {
    errors: [],
    isUserAdmin: process.env.VUE_APP_TESTING === 'true',
    isUserLoading: false,
    mixpanelEnabled: process.env.NODE_ENV,
    adminRole: 'SCHEDULEADMIN',
    trainingRequired: false
  },
  mutations: {
    setBlocks (state, payload) {
      state.blocks = payload
    },
    setFilterBlocks (state, payload) {
      state.filters.blocks = payload
    },
    setFilterStartDate (state, payload) {
      state.filters.startDate = payload
    },
    setFilterEndDate (state, payload) {
      state.filters.endDate = payload
    },
    setFilterConsecutive (state, payload) {
      state.filters.consecutive = payload
    },
    setEdit (state) {
      state.mode = 'edit'
    },
    setView (state) {
      state.mode = 'view'
    },
    logError (state, err) {
      Sentry.captureException(err.response || err)
      state.errors.push(err.response || err)
    },
    updatePresentationMode (state) {
      state.presentMode = !state.presentMode
    },
    setRequest (state, payload) {
      state.reservation = payload
    },
    setIsUserAdmin (state, payload) {
      state.isUserAdmin = payload
    },
    setIsUserLoading (state, payload) {
      state.isUserLoading = payload
    },
    setTrainingRequired (state, payload) {
      state.trainingRequired = payload
    }
  },
  getters: {
    getPresentMode: state => state.presentMode,
    mixpanelEnabled: state => state.mixpanelEnabled === 'production',
    getErrors: state => state.errors,
    isUserAdmin: state => state.isUserAdmin,
    isUserEdu (state) {
      const isEmailEdu = /.*\.edu$/.test(state.session.user.username)
      if (!isEmailEdu) {
        return false
      }
      return true
    }
  }
})
