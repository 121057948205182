<template>
  <v-sheet height="440">
    <v-calendar
      @click:day="startBooking"
      @click:date="startBooking"
    >
      <template
        v-if="availability"
        slot="day"
        slot-scope="{ date }"
      >
        <span
          v-if="hasReservation(date)"
        >
          <font-awesome-icon
            v-if="hasApprovedReservation(date)"
            size="2x"
            color="#00274c"
            :icon="['far', 'car']"
            data-cy="calendar-car-approved"
          />
          <font-awesome-icon
            v-if="hasPendingReservation(date)"
            size="2x"
            color="#FFCB05"
            :icon="['far', 'car']"
            data-cy="calendar-car-pending"
          />
        </span>
        <template
          v-else
        >
          <font-awesome-icon
            v-if="checkAvailability(date)"
            size="2x"
            color="green"
            :icon="['far', 'check-circle']"
            data-cy="calendar-check"
          />
          <font-awesome-icon
            v-else
            size="2x"
            color="tomato"
            :icon="['far', 'times-circle']"
            data-cy="calendar-x"
          />
        </template>
      </template>
    </v-calendar>
  </v-sheet>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isSameDay, isWithinRange, startOfDay, endOfDay, parse } from 'date-fns'
export default {
  computed: {
    ...mapState('reservation', {
      availability: state => state.overviewAvailability,
      reservations: state => state.reservations
    })
  },
  methods: {
    ...mapActions('reservation/transactions', [
      'getOverviewAvailabilityAction'
    ]),
    checkAvailability (date) {
      const match = this.availability.find(item => {
        return isSameDay(parse(item.date), parse(date))
      })
      if (match) {
        const isAMFree = match.timeslots.find(timeslot => timeslot.timeslot_cd === 'AM').free
        const isPMFree = match.timeslots.find(timeslot => timeslot.timeslot_cd === 'PM').free
        return isAMFree || isPMFree
      } else {
        return false
      }
    },
    startBooking (date) {
      this.$router.push(`/search?date=${date.date}`)
    },
    hasReservation (date) {
      return this.reservations.some(reservation => {
        const res = isWithinRange(parse(date), startOfDay(reservation.start_date), endOfDay(reservation.end_date))
        return res
      })
    },
    hasApprovedReservation (date) {
      return this.reservations.some(reservation => {
        return isWithinRange(parse(date), startOfDay(reservation.start_date), endOfDay(reservation.end_date)) && reservation.reservation_title.includes('APPROVED')
      })
    },
    hasPendingReservation (date) {
      return this.reservations.some(reservation => {
        return isWithinRange(parse(date), startOfDay(reservation.start_date), endOfDay(reservation.end_date)) && reservation.reservation_title.includes('PENDING')
      })
    }
  },
  mounted () {
    this.getOverviewAvailabilityAction()
  }
}
</script>

<style>
</style>
