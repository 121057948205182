<template>
  <v-container
    class="elevation-5"
  >
    <v-layout
      row
      class="selection-boxes"
    >
      <v-flex
        class="input-boxes_col"
        xs6
      >
        <div class="outline-radio ">
          <div class="input-box-labels">
            Time Slots
          </div>
          <v-select
            v-model="shownTimeslots"
            multiple
            chips
            full-width
            flat
            solo
            deletable-chips
            :items="timeslots"
            :item-text="({timeslot_desc, end_time, start_time}) => `${timeslot_desc}  ${start_time} - ${end_time}`"
            item-value="timeslot_display_order"
          />
        </div>
        <div
          class="outline-radio"
          v-if="isUserEdu"
        >
          <div class="input-box-labels">
            Track Selection
          </div>
          <v-radio-group
            class="track-selection-radio"
            v-model="selectedFacility"
            row
          >
            <v-radio
              label="Full (Urban and Highway)"
              value="Full"
            />
            <v-radio
              label="Urban"
              value="Urban"
            />
            <v-radio
              label="Highway"
              value="Highway"
            />
          </v-radio-group>
        </div>
      </v-flex>

      <v-flex
        class="input-boxes_col"
        xs6
      >
        <div class="outline-radio">
          <div class="input-box-labels">
            Start Date
          </div>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="startDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              v-model="startDate"
              prepend-inner-icon="fa-calendar-alt"
              readonly
            />
            <v-date-picker
              v-model="startDate"
              no-title
              scrollable
              color="primary"
              :min="today"
            >
              <v-spacer />
              <v-btn
                flat
                color="primary"
                @click="menu = false"
                class="min-width-100"
              >
                Cancel
              </v-btn>
              <v-btn
                flat
                color="primary"
                @click="$refs.menu.save(startDate)"
                class="min-width-100"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div class="outline-radio ">
          <div class="input-box-labels">
            Number of Consecutive Days
          </div>
          <v-select
            v-model="numDays"
            prepend-inner-icon="fa-hashtag"
            :items="consecutiveOptions"
            data-cy="num-day-selector"
          />
        </div>
      </v-flex>
    </v-layout>
    <div>
      Note: All dates and times are in US Eastern Time (EST).
    </div>
    <v-divider class="mt-2" />
    <div class="cal-outline">
      <v-data-table
        :headers="heads"
        :items="items"
        :rows-per-page-items="paginationOptions"
        :pagination.sync="pagination"
      >
        <template
          slot="headers"
          slot-scope="{ headers }"
        >
          <tr>
            <th
              style="min-width: 110px;"
              class="column sortable active caption font-weight-medium black--text wide"
              :class="[pagination.descending ? 'desc' : 'asc']"
              @click="pagination.descending = !pagination.descending"
            >
              Day
              <font-awesome-icon
                icon="sort-up"
                :transform="{ rotate: pagination.descending ? 180 : 0 }"
              />
            </th>
            <th
              v-for="header in headers"
              :key="header.text"
              :style="`background-color: ${header.color};`"
              class="column caption font-weight-medium black--text"
            >
              <p class="my-0">
                {{ header.text }}
              </p>
              <p class="my-0">
                {{ header.times }}
              </p>
              <p class="my-0">
                {{ header.note }}
              </p>
            </th>
          </tr>
        </template>

        <template
          slot="items"
          slot-scope="{ item, selected }"
        >
          <tr
            :active="selected"
            @click="selected = !selected"
          >
            <td>
              {{ moment.tz(item.date, 'America/Detroit').format('ddd MMM DD YYYY') }}
            </td>
            <td
              v-for="timeslot in item.timeslots"
              :key="timeslot.timeslot_display_order"
              :class="getColumnColorClass(timeslot.timeslot_cd)"
            >
              <v-tooltip
                top
                v-if="showBookButton(timeslot) || !timeslot.isLastEvent"
              >
                <v-btn
                  slot="activator"
                  color="primary"
                  class="min-width-100"
                  @click="confirmReservation(item, timeslot, selectedFacility)"
                  data-cy="available-book"
                  v-if="selectedFacility === 'Full'"
                  :disabled="!timeslot.free || timeslot.partial_booking.length > 0"
                >
                  Book Full Track
                </v-btn>
                <v-btn
                  slot="activator"
                  color="primary"
                  class="min-width-100"
                  @click="confirmReservation(item, timeslot, 'Urban')"
                  data-cy="available-book"
                  v-if="selectedFacility === 'Urban'"
                  :disabled="!timeslot.free || timeslot.partial_booking.includes('urban')"
                >
                  Book Urban
                </v-btn>
                <v-btn
                  slot="activator"
                  color="primary"
                  class="min-width-100"
                  @click="confirmReservation(item, timeslot, 'Highway')"
                  data-cy="available-book"
                  v-if="selectedFacility === 'Highway'"
                  :disabled="!timeslot.free || timeslot.partial_booking.includes('highway')"
                >
                  Book Highway
                </v-btn>

                <span>{{ getEndDate(item.date) }}</span>
              </v-tooltip>

              <v-tooltip
                top
                v-else-if="timeslot.isLastEvent"
              >
                <font-awesome-icon
                  slot="activator"
                  size="2x"
                  class="primary--text"
                  :icon="['far', 'calendar-exclamation']"
                />
                <span>This start date is unavailable for the multi-day window.</span>
              </v-tooltip>

              <font-awesome-icon
                v-else
                size="2x"
                color="tomato"
                icon="ban"
              />
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <v-dialog
      v-model="showConfirm"
      width="500"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <h2 class="heading">
            Confirm
          </h2>
        </v-card-title>
        <v-card-text class="text-xs-left">
          <p>Selected reservation is for {{ numDays[0] }} day(s).</p>
          <p>Selected timeslot is {{ currentSelection.timeslot }}.</p>
          <p>
            Reservation window starts on {{ currentSelection.startDate }}
            and ends on {{ currentSelection.endDate }}.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="showConfirm = false"
            class="min-width-100"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="bookReservation"
            class="min-width-100"
            color="primary"
            data-cy="available-confirm"
          >
            Confirm and Book
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import cloneDeep from 'lodash-es/cloneDeep'
import moment from 'moment-timezone'
export default {
  data () {
    return {
      calendarStart: null,
      pagination: { sortBy: 'date', descending: false, page: 1 },
      menu: null,
      startDate: moment.tz('America/Detroit').format('YYYY-MM-DD'),
      today: moment.tz('America/Detroit').format('YYYY-MM-DD'),
      maxConsecutive: 10,
      shownTimeslots: [2, 4, 5],
      selectedFacility: 'Full',
      numDays: [1],
      paginationOptions: [14, 7, 10, 28],
      currentSelection: {
        startDate: '',
        endDate: '',
        timeslot: ''
      },
      bookPayload: {
        start_date: null,
        end_date: null
      },
      showConfirm: false,
      moment: moment
    }
  },
  methods: {
    ...mapActions('reservation/transactions', [
      'getAllAvailabilityAction'
    ]),
    ...mapMutations('reservation', [
      'setReservationForm'
    ]),
    showBookButton (timeslot) {
      if (!timeslot.free) {
        return false
      }

      if (timeslot.partial_booking.length > 0 && !this.isUserEdu) {
        return false // Commercial members may not book partials
      }
      return true
    },
    getColumnColorClass (timeslotName) {
      const colors = {
        FULL: 'active timeslot-green',
        EARLY: 'active timeslot-orange',
        AM: 'active timeslot-blue',
        PM: 'active timeslot-light-blue',
        LATE: 'active timeslot-purple'
      }
      return colors[timeslotName] || 'white'
    },
    setStartDate (e) {
      if (e.future) this.startDate = e.date
    },
    confirmReservation (row, timeslot, partialReservation) {
      const end = moment.tz(row.date, 'America/Detroit').add(this.numDays[0] - 1, 'days')
      const start = moment.tz(row.date, 'America/Detroit')
      this.bookPayload = {
        start_date: start.toISOString(),
        end_date: end.toISOString(),
        timeslot: timeslot.timeslot_cd,
        partial_reservation: partialReservation,
        partial_already_booked: timeslot.partial_booking
      }
      this.currentSelection.startDate = moment.tz(row.date, 'America/Detroit').format('ddd MMM DD YYYY')
      this.currentSelection.endDate = moment.tz(end, 'America/Detroit').format('ddd MMM DD YYYY')
      this.currentSelection.timeslot = timeslot.timeslot_cd
      this.showConfirm = true
    },
    bookReservation () {
      this.setReservationForm(this.bookPayload)
      this.$router.push('/create')
    },
    getEndDate (date) {
      const startDate = moment.tz(date, 'America/Detroit')
      if (this.numDays[0] === 1) return startDate.format('ddd MMM DD YYYY')
      else {
        const endDate = moment(startDate).add(this.numDays[0] - 1, 'days')
        return `${startDate.format('ddd MMM DD YYYY')} - ${endDate.format('ddd MMM DD YYYY')}`
      }
    }
  },
  computed: {
    ...mapState({
      timeslots: state => state.dimensions.timeslots,
      availability: state => state.reservation.allAvailability,
      userEmail: state => state.session.user.username
    }),
    ...mapGetters(['isUserEdu']),
    heads () {
      return this.timeslots
        ? this.timeslots
          .map(timeslot => ({
            text: timeslot.timeslot_desc,
            times: timeslot.tour_allowed
              ? `${timeslot.start_time} - ${timeslot.end_time}*`
              : `${timeslot.start_time} - ${timeslot.end_time}`,
            note: timeslot.tour_allowed ? `*Tours/Maintenance: ${timeslot.tour_start_time} - ${timeslot.tour_end_time}` : '',
            timeslot_display_order: timeslot.timeslot_display_order,
            color: `${timeslot.timeslot_display_color}`
          })).filter(header => this.shownTimeslots.includes(header.timeslot_display_order))
        : []
    },
    items () {
      if (this.numDays[0] === 1) {
        return this.itemsFilteredHeaders
      } else {
        const numDays = this.numDays[0]
        const newItems = cloneDeep(this.itemsFilteredHeaders) // Deep clone to not modify original object
        newItems.forEach((row, rowInd, arr) => {
          row.timeslots.forEach((timeslot, timeslotInd) => {
            let free = true
            if (timeslot.free === false) {
              free = false
            } else {
              for (let i = 1; i < numDays; i++) {
                if (rowInd + i > newItems.length - 1) { // Out of bounds checking
                  free = false
                  break
                }
                if (newItems[rowInd + i].timeslots[timeslotInd].free === false) {
                  free = false
                  break
                }
              }
              if (free) {
                for (let j = 1; j < numDays; j++) {
                  if (rowInd + j < newItems.length) {
                    newItems[rowInd + j].timeslots[timeslotInd].isLastEvent = true
                  }
                }
              }
            }
            if (!free) {
              timeslot.free = false
            }
          })
        })
        newItems.splice(newItems.length - numDays)
        return newItems
      }
    },
    itemsFilteredHeaders () {
      const items = this.availability.map(item => {
        const row = { ...item } // To not mutate the current item
        row.date = moment.tz(item.date, 'America/Detroit').format('YYYY-MM-DD')
        // Add isLastEvent field for multiday bookings
        row.timeslots.map(timeslot => {
          timeslot.isLastEvent = false
          return timeslot
        })
        row.timeslots = item.timeslots.filter(timeslot => {
          return this.shownTimeslots.includes(timeslot.timeslot_display_order)
        })
        return row
      })
      if (this.startDate) {
        return items.filter(item => moment.tz(item.date, 'America/Detroit').isSameOrAfter(moment.tz(this.startDate, 'America/Detroit')))
      } else {
        return items
      }
    },
    consecutiveOptions () {
      const arr = []
      for (let i = 1; i <= this.maxConsecutive; i++) {
        arr[i - 1] = [i]
      }
      return arr
    }
  },
  watch: {
    startDate (val) {
      this.pagination.page = 1
    }
  },
  created () {
    this.getAllAvailabilityAction()
    this.$store.dispatch('dimensions/getTimeslotsAction')
  }
}
</script>

<style>
.active {
  transition: all .2s ease-in-out;
}
.active:hover {
  transform: scale(1.1);
}

.v-btn .min-width-100 {
  min-width: 100px;
}

.timeslot-orange {
  background-color: rgb(247, 139, 72, .20);
}

.timeslot-green {
  background-color: rgb(167, 182, 0, .20);
}

.timeslot-blue {
  background-color: rgb(31, 128, 255, .20);
}

.timeslot-light-blue {
  background-color: rgb(48, 199, 191, .20);
}

.timeslot-purple {
  background-color: rgb(116, 10, 210, .20);
}

.outline-radio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  outline: 2px solid gray;
  border-radius: 2px;
  padding-top: 8px;
  padding-left: 8px;
  background-color: #fff;
  height: 100%;
}

.cal-outline {
  outline: 2px solid gray;
  border-radius: 2px;
}

.track-selection-radio {
  margin: 0px;
}

.input-box-labels {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.input-boxes_col {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: space-evenly;
}

.selection-boxes {
  display: flex;
  column-gap: 20px;
  margin-bottom: 20px;
}

</style>
