import transactions from './reservation-transactions'
import set from 'lodash-es/set'
import { isAfter, isBefore } from 'date-fns'

const defaultReservation = {
  reservation_desc: '',
  start_date: new Date('2019-02-07'),
  end_date: new Date('2019-02-08'),
  timeslot: 'FULL',
  company_name: '',
  primary_name: '',
  primary_email: '',
  primary_phone: '',
  facility_prep: '',
  facility_its: '',
  data_collection: '',
  technician_support: '',
  hardware_install: false,
  hardware_desc: '',
  tech_time: 0.00001,
  billing_name: '',
  billing_address: '',
  billing_email: '',
  billing_phone: '',
  billing_info: '',
  addl_contacts: [{
    name: '',
    email: '',
    phone: '',
    training: false
  }],
  vehicles: [],
  requestFormURL: '',
  reservation_equipment: [],
  reservation_equipment_cost: {},
  reservation_rates: [],
  reservation_cost: [],
  guest_office: false,
  partial_reservation: 'Full',
  grant_funded: false,
  grant_key: 0,
  is_tour: false,
  is_hold: false,
  num_of_attendees: 1
}

const modules = {
  transactions
}

const state = {
  eligibility: null,
  reservations: [],
  reservationsIssues: [],
  reservationsPast: [],
  reservationForm: { ...defaultReservation },
  currentEventID: null,
  safetyPlan: null,
  equipment: null,
  overviewAvailability: null,
  allAvailability: [],
  currentReservation: null,
  reservationsAdmin: [],
  usageHistory: [],
  pendingReservations: []
}

const getters = {
  originalReservations (state) {
    return state.reservations.filter(reservation => reservation.original_event)
  },
  originalReservationsPast (state) {
    return state.reservationsPast.filter(reservation => reservation.original_event).reverse()
  },
  approvedReservations (state) {
    return state.reservationsAdmin.filter(reservation => reservation.approved)
  },
  usageHistory (state) {
    return state.usageHistory.sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime())
  },
  webappReservations (state, getters) {
    return [...state.reservations, ...getters.originalReservationsPast]
      .filter(reservation => reservation.reservation_desc)
      .filter(reservation => reservation.has_safety_plan)
      .sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime())
  },
  reservationsPast (state) {
    return state.reservationsPast
  },
  getSafetyPlan (state) {
    return state.safetyPlan
  }
}

const mutations = {
  setEligibility (state, payload) {
    state.eligibility = payload
  },
  setReservations (state, payload) {
    state.reservationsIssues = payload.filter(reservation => reservation.reservation_desc === null)
    state.reservations = payload
  },
  setReservationsAdmin (state, payload) {
    state.reservationsAdmin = payload
  },
  setUsageHistory (state, payload) {
    state.usageHistory = payload
  },
  setCurrentReservation (state, reservation) {
    // Keys to not overwrite
    const noCopy = ['start_date', 'end_date', 'timeslot']
    state.currentReservation = reservation
    if (reservation) {
      for (const key in state.reservationForm) {
        if (!noCopy.includes(key)) state.reservationForm[key] = reservation[key]
      }
    }
  },
  setReservationsPast (state, payload) {
    state.reservationsPast = payload
  },
  resetReservationForm (state) {
    state.reservationForm = { ...defaultReservation }
  },
  setReservationField (state, payload) {
    set(state.reservationForm, payload.field, payload.data)
  },
  setContactFields (state, payload) {
    state.reservationForm.primary_phone = payload.phone
    state.reservationForm.company_name = payload.company
  },
  setTour (state, payload) {
    state.reservationForm.is_tour = payload
  },
  setHold (state, payload) {
    state.reservationForm.is_hold = payload
  },
  setSafetyPlan (state, fileObj) {
    state.safetyPlan = fileObj
  },
  updateSafetyPlan (state, fileObj) {
    if (state.currentReservation != null) {
      state.currentReservation.safety_plan_media = [fileObj]
    }
  },
  setEquipment (state, payload) {
    state.equipment = payload
  },
  addVehicle (state, vehicle) {
    state.reservationForm.vehicles.push(vehicle)
  },
  removeVehicle (state, index) {
    state.reservationForm.vehicles.splice(index, 1)
  },
  addEquipment (state, item) {
    state.reservationForm.reservation_equipment.push(item)
  },
  initRates (state, ratesList) {
    state.reservationForm.reservation_rates = ratesList
  },
  addEquipmentCost (state, item) {
    state.reservationForm.reservation_equipment_cost.push(item)
  },
  addRentalCost (state, item) {
    state.reservationForm.reservation_cost = item
  },
  removeEquipment (state, code) {
    const index = state.reservationForm.reservation_equipment.indexOf(code)
    index > -1 && state.reservationForm.reservation_equipment.splice(index, 1)
  },
  removeEquipmentCost (state, key) {
    if (state.reservationForm && state.reservationForm.reservation_equipment_cost && key in state.reservationForm.reservation_equipment_cost) {
      delete state.reservationForm.reservation_equipment_cost[key]
    }
  },
  addAdditionalContact (state, contact) {
    state.reservationForm.addl_contacts.push(contact)
  },
  removeAdditionalContact (state, index) {
    state.reservationForm.addl_contacts.splice(index, 1)
  },
  setOverviewAvailability (state, availability) {
    state.overviewAvailability = availability
  },
  setAllAvailability (state, availability) {
    state.allAvailability = availability
  },
  setReservationForm (state, payload) {
    Object.assign(state.reservationForm, defaultReservation, payload)
  },
  setCurrentEventID (state, payload) {
    state.currentEventID = payload
  },
  setPendingReservations (state, payload) {
    const pendingReservations = []
    const idmap = {}
    for (const [, res] of payload.entries()) { // iterate over map, ignoring key and just using the value
      if (!(res.original_event in idmap)) { // Single-day reservation or first found instance of multi-day reservation
        // Add reservation to pending reservations array, and save the index
        idmap[res.original_event] = pendingReservations.push(res) - 1
      } else { // Multi-day reservation
        // Update the start or end date of the multi-day reservation, as appropriate
        if (isAfter(res.end_date, pendingReservations[idmap[res.original_event]].end_date)) {
          pendingReservations[idmap[res.original_event]].end_date = res.end_date
        } else if (isBefore(res.start_date, pendingReservations[idmap[res.original_event]].start_date)) {
          pendingReservations[idmap[res.original_event]].start_date = res.start_date
        }
      }
    }
    state.pendingReservations = pendingReservations
  },
  setNumOfAttendees (state, payload) {
    state.reservationForm.num_of_attendees = payload
  }
}

export default {
  namespaced: true,
  modules,
  state,
  getters,
  mutations
}
