// skipping the api & store import at first.
import api from '../../api'

const {
  getWaitlists
} = api

const actions = {
  getWaitlistsAction ({ commit }) {
    return getWaitlists()
      .then(response => commit('waitlist/setWaitlists', response.data.waitlist, { root: true }))
      .catch(e => commit('logError', e, { root: true }))
  }
}

export default {
  namespaced: true,
  actions
}
