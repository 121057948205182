<template>
  <div>
    <v-card>
      <v-card-title class="headline primary white--text">
        Reservations
      </v-card-title>
      <v-alert
        :value="showAlert"
        outline
        class="mx-2"
      >
        An event is happening now or begins in the next 30 minutes
      </v-alert>
      <v-progress-circular
        indeterminate
        class="mt-2"
        size="50"
        v-if="showLoader"
      />
      <v-card-text class="text-xs-left">
        <template
          v-if="reservations.filter(
            reservation => reservation.reservation_desc !== null
          ).length === 0"
        >
          <span class="subheading">You don't have any active reservations.</span>
        </template>
      </v-card-text>
      <v-list two-line>
        <event
          v-for="(reservation, ind) in reservations.filter(
            reservation => reservation.reservation_desc !== null
          )"
          :key="ind"
          :event="reservation"
          data-cy="reservations-tile"
        >
          <v-list-group
            slot-scope="{
              formatTitleDate,
              eventStartingSoon,
              getStatus,
              formatFullDate
            }"
          >
            <template slot="activator">
              <v-list-tile>
                <v-list-tile-action>
                  <v-tooltip left>
                    <status-light
                      slot="activator"
                      :status="getStatus"
                    />
                    <span>{{ getStatus }}</span>
                  </v-tooltip>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{
                      reservation.reservation_desc ||
                        reservation.reservation_title
                    }}
                  </v-list-tile-title>
                  <v-list-tile-sub-title
                    :class="{
                      'error--text font-weight-bold': eventStartingSoon
                    }"
                  >
                    {{ formatTitleDate() }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn
                    flat
                    v-if="reservation.reservation_desc"
                    @click.stop="viewReservation(reservation)"
                    data-cy="reservations-preview"
                  >
                    <font-awesome-icon
                      :icon="['far', 'file-invoice']"
                      size="2x"
                    />
                  </v-btn>
                </v-list-tile-action>
                <v-list-tile-action>
                  <v-tooltip left>
                    <v-btn
                      flat
                      slot="activator"
                      @click.stop="editReservation(reservation)"
                      data-cy="reservations-edit"
                    >
                      <font-awesome-icon
                        :icon="['far', 'edit']"
                        size="2x"
                      />
                    </v-btn>
                    <span>Edit</span>
                  </v-tooltip>
                </v-list-tile-action>
              </v-list-tile>
            </template>
            <v-list-tile
              v-for="(event, ind2) in reservation.events"
              :key="ind2"
            >
              <v-list-tile-content>
                {{
                  formatFullDate(event.start_date)
                }}
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn
                  color="error"
                  @click="openCancelDialog(event)"
                  data-cy="reservations-cancel"
                >
                  Cancel
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </v-list-group>
        </event>
      </v-list>

      <v-card-actions>
        <v-btn
          color="primary"
          to="/search"
          data-cy="reservations-new"
        >
          Book New
        </v-btn>
        <v-tooltip right>
          <v-btn
            flat
            slot="activator"
            to="/training"
            v-if="trainingRequired"
          >
            <font-awesome-icon
              size="2x"
              class="error--text"
              :icon="['far', 'exclamation-circle']"
            />
          </v-btn>
          <font-awesome-icon
            v-else
            slot="activator"
            size="2x"
            class="success--text"
            :icon="['far', 'check-circle']"
            style="margin-left:10px;"
          />
          <span
            v-if="trainingRequired"
          >Training must be completed before the reservation date</span>
          <span v-else>Track Training Completed</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="showConfirm"
      width="500"
    >
      <v-card data-cy="reservations-cancel-dialog">
        <v-card-title class="primary white--text">
          <h2 class="heading">
            Confirm
          </h2>
        </v-card-title>
        <v-card-text class="text-xs-left">
          <p>Are you sure you want to cancel the following day?</p>
          {{ cancelDateFormatted }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showConfirm = false">
            Close
          </v-btn>
          <v-btn
            @click="cancelDay"
            color="error"
            data-cy="reservations-cancel-confirm"
          >
            Cancel Day
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* This component displays the list of reservations for all events which have an attendee matching the current users email */
import { mapMutations, mapActions, mapState } from 'vuex'
import { format } from 'date-fns'
import StatusLight from './StatusLight'
import Event from './Event'
export default {
  components: {
    StatusLight,
    Event
  },
  data () {
    return {
      showConfirm: false,
      reservationToCancel: null,
      showLoader: false
    }
  },
  computed: {
    ...mapState('reservation', ['reservations']),
    ...mapState({
      user: state => state.session.user,
      trainingRequired: state => state.trainingRequired
    }),
    showAlert () {
      const now = new Date()
      // Returns true if any reservation starts in next 30 mins or is currently active
      return (
        this.reservations &&
        this.reservations.some(event => {
          const start = new Date(event.start_date)
          const end = new Date(event.end_date)
          return now > start - 1800000 && now < end // 1800000 is 30 minutes
        })
      )
    },
    cancelDateFormatted () {
      return this.reservationToCancel
        ? format(this.reservationToCancel.start_date, 'YYYY-MM-DD')
        : ''
    }
  },
  methods: {
    ...mapMutations('reservation', ['setCurrentEventID']),
    ...mapActions('reservation/transactions', [
      'getReservationsAction',
      'cancelEventAction',
      'getTrainingAction'
    ]),
    editReservation (reservation) {
      this.setCurrentEventID(reservation.parent_id)
      this.$router.push(`/reservation/${reservation.parent_id}/edit`)
    },
    cancelDay () {
      this.showLoader = true
      this.cancelEventAction(this.reservationToCancel.event_id)
        .then((this.showLoader = false))
        .catch((this.showLoader = false))
      this.showConfirm = false
    },
    openCancelDialog (event) {
      this.showConfirm = true
      this.reservationToCancel = event
    },
    viewReservation (reservation) {
      this.setCurrentEventID(reservation.parent_id)
      this.$router.push(`/reservation/${reservation.parent_id}/view`)
    }
  },
  mounted () {
    this.getReservationsAction()
    this.getTrainingAction(this.user.username)
  }
}
</script>
