<template>
  <v-card>
    <v-card-title class="headline primary white--text">
      Past Reservations
    </v-card-title>
    <v-list
      two-line
      class="scroll-list"
    >
      <v-card-text class="text-xs-left">
        <template
          v-if="reservationsPast.length === 0"
        >
          <span class="subheading">You don't have any past reservations.</span>
        </template>
      </v-card-text>
      <event
        v-for="(reservation, ind) in reservationsPast"
        :key="ind"
        :event="reservation"
      >
        <v-list-tile
          slot-scope="{ formatTitleDate, getStatus }"
        >
          <v-list-tile-action>
            <v-tooltip top>
              <status-light
                slot="activator"
                :status="getStatus"
              />
              <span>{{ getStatus }}</span>
            </v-tooltip>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ reservation.reservation_desc || reservation.reservation_title }}
            </v-list-tile-title>
            <v-list-tile-sub-title>
              {{ formatTitleDate() }}
            </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-btn
              flat
              v-if="reservation.reservation_desc"
              @click="viewReservation(reservation)"
            >
              <font-awesome-icon
                :icon="['far', 'file-invoice']"
                size="2x"
              />
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </event>
    </v-list>
  </v-card>
</template>

<script>
/* This component displays the list of reservations for all events which have an attendee matching the current users email */
import { mapMutations, mapActions, mapGetters } from 'vuex'
import StatusLight from './StatusLight'
import Event from './Event'
export default {
  components: {
    StatusLight,
    Event
  },
  computed: {
    ...mapGetters('reservation', ['originalReservationsPast', 'reservationsPast']),
    showAlert () {
      const now = new Date()
      // Returns true if any reservation starts in next 30 mins or is currently active
      return this.originalReservationsPast && this.originalReservationsPast.some(event => {
        const start = new Date(event.start_date)
        const end = new Date(event.end_date)
        return now > start - 1800000 && now < end // 1800000 is 30 minutes
      })
    }
  },
  methods: {
    ...mapMutations('reservation', [
      'setCurrentEventID'
    ]),
    ...mapActions('reservation/transactions', [
      'getReservationsPastAction'
    ]),
    viewReservation (reservation) {
      this.setCurrentEventID(reservation.original_event)
      this.$router.push(`/reservation/${reservation.original_event}/view`)
    }
  },
  mounted () {
    this.getReservationsPastAction()
  }
}
</script>

<style>
 .scroll-list {
   max-height: 70vh;
   overflow-y: scroll;
 }
</style>
