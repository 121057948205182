import api from '../../api'

const {
  getTimeslots
} = api

const state = {
  timeslots: null
}

const mutations = {
  setTimeslots (state, payload) {
    state.timeslots = payload
  }
}

const actions = {
  getTimeslotsAction ({ commit, state }) {
    if (state.timeslots === null) {
      return getTimeslots()
        .then(response => commit('setTimeslots', response.data.timeslots))
        .catch(e => commit('logError', e, { root: true }))
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
