<template>
  <div>
    <v-card>
      <v-card-title class="headline primary white--text">
        Issues
      </v-card-title>
      <v-card-text v-if="reservationsIssues.length === 0">
        <font-awesome-icon
          icon="check"
          color="green"
          size="2x"
          class="mr-2"
        />
        <span class="subheading">No reservations currently have issues.</span>
      </v-card-text>
      <v-list
        v-else
        two-line
      >
        <event
          v-for="(reservation, ind) in reservationsIssues"
          :key="ind"
          :event="reservation"
        >
          <v-list-group
            slot-scope="{ formatTitleDate, eventStartingSoon, getStatus, formatFullDate }"
          >
            <template slot="activator">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <font-awesome-icon
                    size="2x"
                    class="error--text ml-3"
                    :icon="['far', 'exclamation-circle']"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>More info needed — edit reservation to complete</span>
              </v-tooltip>
              <v-list-tile>
                <v-list-tile-action>
                  <v-tooltip left>
                    <status-light
                      slot="activator"
                      :status="getStatus"
                    />
                    <span>{{ getStatus }}</span>
                  </v-tooltip>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ reservation.reservation_desc || reservation.reservation_title }}
                  </v-list-tile-title>
                  <v-list-tile-sub-title
                    :class="{ 'error--text font-weight-bold': eventStartingSoon }"
                  >
                    {{ formatTitleDate() }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-tooltip left>
                    <v-btn
                      flat
                      slot="activator"
                      @click.stop="editReservation(reservation)"
                    >
                      <font-awesome-icon
                        :icon="['far', 'edit']"
                        size="2x"
                      />
                    </v-btn>
                    <span>Edit</span>
                  </v-tooltip>
                </v-list-tile-action>
              </v-list-tile>
            </template>
            <v-list-tile
              v-for="(event, ind2) in reservation.events"
              :key="ind2"
            >
              <v-list-tile-content>{{ formatFullDate(event.start_date) }}</v-list-tile-content>
              <v-list-tile-action>
                <v-btn
                  color="error"
                  @click="openCancelDialog(event)"
                >
                  Cancel
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </v-list-group>
        </event>
      </v-list>
    </v-card>
    <v-dialog
      v-model="showConfirm"
      width="500"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <h2 class="heading">
            Confirm
          </h2>
        </v-card-title>
        <v-card-text class="text-xs-left">
          <p>Are you sure you want to cancel the following day?</p>
          {{ cancelDateFormatted }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="showConfirm = false"
          >
            Close
          </v-btn>
          <v-btn
            @click="cancelDay"
            color="error"
          >
            Cancel Day
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { format } from 'date-fns'
import StatusLight from './StatusLight'
import Event from './Event'
export default {
  components: {
    StatusLight,
    Event
  },
  data: () => ({
    showConfirm: false,
    reservationToCancel: null,
    showLoader: false
  }),
  computed: {
    ...mapState('reservation', ['reservationsIssues']),
    cancelDateFormatted () {
      return this.reservationToCancel
        ? format(this.reservationToCancel.start_date, 'YYYY-MM-DD')
        : ''
    }
  },
  methods: {
    ...mapMutations('reservation', [
      'setCurrentEventID'
    ]),
    ...mapActions('reservation/transactions', [
      'getReservationsAction',
      'cancelEventAction'
    ]),
    cancelDay () {
      this.showLoader = true
      this.cancelEventAction(this.reservationToCancel.event_id)
        .then(this.showLoader = false)
        .catch(this.showLoader = false)
      this.showConfirm = false
    },
    openCancelDialog (event) {
      this.showConfirm = true
      this.reservationToCancel = event
    },
    editReservation (reservation) {
      this.setCurrentEventID(reservation.parent_id)
      this.$router.push(`/reservation/${reservation.parent_id}/edit`)
    }
  }
}
</script>

<style>

</style>
